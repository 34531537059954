.blog_grid_container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    margin-top: 20px;
}

.blog_grid_item{
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    border-radius: 10px;
    margin: 50px 20px;
}

.blog_grid_img{
    height: 4rem; 
    object-fit: contain;
    background-color: #000;
    padding: 80px;   border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.blog_grid_img img {
    height: inherit;
    margin-left: -40px;
}
 
.blog_grid_item_content{
    margin: 25px;
}

.blog_grid_item_content h2{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.8rem;
}