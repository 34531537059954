.signUpPage{
    width: 100%;
    height: 100vh;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
     background: linear-gradient(to left, rgb(159, 147, 147), #333);
    
}
.signUpImage{
    height: 70%;
}
.signUpLeft{ 
    animation: animateSignUpLeft 1s;
}
/* .signUpLeft img { 
    max-height: 800px;
} */
.signUpContainer{
  height: 90%;  
  width: 45%;
  min-width: 500px;
  min-height: 900px;
  max-width: 800px;
   background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    padding: 0;
    overflow: auto;
}
.signUpContainer .signUpContainerChild {
   
    width: 80%;
    
}
.studentSignUp{
    width: 100%; 
}
 
.studentSignUp .form-group input,
.schoolSignUp .form-group input{
    padding: 12px;
    width: 100%;
}

.studentSignUp, .schoolSignUp{
    animation: fadeInForm .44s ease;
}

@keyframes fadeInForm {
    0% {
        opacity: 0;
        transform: translate3d(0, -1px, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
    
@keyframes animateSignUpLeft{
    0%{
        opacity: 0;
        transform: translateX(-100%);
    }
    100%{
        opacity: 1;
        transform: translateX(0%);
    }
}

@media screen and (max-width: 1100px){
    .signUpPage{
       flex-direction: column;
    }
    .signUpLeft{
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .signUpContainer{ 
        min-width: 200px;
        width: 90%;
    }
}