.slide_container_test{
    padding: 20px;
    background: var(--main-bg)
}
.slide_content_test{
    
    display: flex;  
    flex-direction: row;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
}

@media screen and (max-width: 1200px){
    .slide_content_test_img_container{
        display: none;
    }
} 

@media screen and (max-width: 1000px){
    .slide_content_test{
     
       
    }
    .testSliderBullets > li{
         font-size: 12px;
         /* background-color: orange; */
    }
} 
.testSliderBullets li{
    font-family: var(--font_family_main);
    font-weight: bold;
    font-size: 25px;
    margin: 10px 0;
    list-style-type: square;
}

.startTestDiv button {
    padding: 20px 35px;
    font-size: 20px;
    margin: 10px 0;
    background: #62b4ff;
    color: #fff;
    border-radius: 5px;
    transition: 0.3s ease;
    box-shadow: 1px 1px 1px 3px #ccc;
    font-family:  'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.startTestDiv button:hover{
    background: #fff;
    color: #62b4ff;
    box-shadow: 1px 1px 1px 3px #ccc;
}
@media screen and (max-width: 800px) {
    .slide_content_test{
        flex-direction: column;
        overflow: scroll; 
    }
} 