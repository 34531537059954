.pulsatingCircleContainer{

    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    animation: pulsatingCircleAnimation 1s ease both infinite;
}

.pulsatingCircle{
    width: 85%;
    height: 85%;
    border-radius: 50%;
    background: rgb(130, 130, 130);
}

.googleMapIndicator p{
    font-size: 10px;
    color: #000;
    background: #333;
    font-weight: bold;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

@keyframes pulsatingCircleAnimation {
    0% {
        transform: scale(0);
        opacity: 1
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
 