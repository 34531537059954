/* FOOTER */
 .grid_footer{
     display: grid;
     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
     align-items: baseline;
     justify-content: center;
     padding: 50px 80px;
     background: #000;
 }

 .grid_item{
     display: flex;
     flex-direction: column; 
     padding: 30px 0;
 }
 .grid_item h2{
     color: rgb(92, 92, 92);
     font-family: var(--font_family_secondary);
     font-size: 20px;
     padding-bottom: 20px;
 } 

.footer_links{
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    font-family: var(--font_family_secondary);
    margin: 5px 0;
    /* background: magenta; */
    width: 13rem;
}

.bottom_footer_text{
    color: #fff;
    text-align: center;
    background: #000;
      font-family: var(--font_family_main);
      font-size: 12px;
      padding: 15px 0;
}