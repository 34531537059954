.learn_more_second{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding-top: 50px;
 height: 400px;
    background: rgb(205, 205, 205);
    
    position: relative;
 
}
.first_item{
    font-size: 18px;
    width: 45%;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.second_item{
    font-size: 18px;
    margin-top: 45px;
    font-weight: bold;
    width: 35%;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.learn_more_second_img{
    position: absolute;
    right: 0;
    top: 15px; 
    height: 5em; 
  
}

.learn_more_second_img.bottom{
    position: absolute;
    left: 0;
    top: 300px; 
    height: 5em;  
    width: 9em
}
.learn_more_second_img img{
 width: inherit;
 height: inherit;
}

.learn_more_second_button {
    background-color: #fff;
    border: 1px solid rgb(201, 201, 201);
    color: #000;
    padding: 15px 32px;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin-top: 40px;
    cursor: pointer;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    transition: 0.45s ease-in-out;
}

.learn_more_second button:hover{
    transform: translate3d(0, -4px, 0);
}

@media screen and (max-width: 560px) {

    .learn_more_second .first_item{ 
        margin-top: 50px;
       font-size: 15px;
        }
    .learn_more_second .second_item{ 
       display: none; 
        }
         .learn_more_second_button{
            margin-top: 10px;
            font-size: 15px;
         }
}