.layout {
  color: var(--txt-color);
}

.layout__content {
  padding-left: var(--sidebar-width);
  background-color: var(--second-bg);
  min-height: 100vh;
}

.layout__content-main {
  padding: 30px;
}

/* DASHBOARD */

.dashboardCardsMain { 
  width: 100%;
  display: flex;
  justify-items:  space-between ;
}

.dashboardCardsMain > * {
  width: 300px;
}
@media screen and (max-width: 1300px) {
  .dashboardCardsMain {
    flex-direction: column;
    overflow: auto;
  }
  .dashboardCardsMain > *{
      width: 100%;
      min-width: 320px;
  }
  .dashboardCardsContainer{ 
    min-width: 500px; 
  }
}
.tweaked_card {
  height: 300px;
  overflow-y: auto;
}
.card__results-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card__controls {
  padding: 10px 0;
  flex: 0.3;
}
.card__controls p {
  margin: 10px 0;

  box-shadow: 1px 1px 5px #ccc;
  padding: 8px 10px;
  border-radius: 15px;
  cursor: pointer;
  transition: 1s ease;
  text-align: center;
}

.card__controls p:hover {
  opacity: 0.85;
}
.activeTemperament {
  background-color: #62b4ff;
  color: #fff;
  font-weight: bold;
}
.card__results-body {
  flex: 1;
  margin: 15px;
  padding: 10px 25px;
  border-radius: 5px;
}

.card__results-body ul {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0, -3px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.card__results-body li {
  margin: 10px 0;
}

/* PROFILE SCREEN */

.profile-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile-container__left {
  box-shadow: 1px 1px 5px rgb(204, 204, 204);
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  flex: 0.5;
  margin: 10px;
  margin-left: 0;
  max-height: 400px;
}
.profile-container__right {
  box-shadow: 1px 1px 5px rgb(204, 204, 204);
  border-radius: 5px;
  background-color: #fff;
  flex: 1;
  margin: 10px;
  padding: 20px;
}

.container_left_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container_left_details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.container_left_details p {
  margin: 10px 0;
}
.profile-container__left__image {
  object-fit: contain;
  width: 200px;
  height: 200px;
  border-radius: 15px;
}

.profile-container__left__image img {
  object-fit: contain;
  width: inherit;
  height: inherit;
  border-radius: 15px;
}

.container_left_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  padding: 20px 0;
  border-top: 2px solid rgb(225, 225, 225);
  /* border-bottom: 2px solid rgb(225, 225, 225); */
  width: 100%;
}

.container_left_bottom__item {
  background: #62b4ff;
  color: #fff;
  padding: 12px 15px;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 3px rgb(146, 146, 146);
  cursor: pointer;
  transition: 0.77s ease;
}

.container_left_bottom__item.dark {
  background: #000;
}

.container_left_bottom__item:hover {
  opacity: 0.85;
  transform: translate3d(0, -3px, 0);
}

/*  */

.container_right_top {
  width: 100%;
}
.container_right_top h3,
.container_right_bottom h3 {
  border-bottom: 2px solid rgb(225, 225, 225);
  padding: 20px 0;
}

.container_right_top form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.container_right_top form input {
  border: 1px solid #ccc;

  padding: 15px;
  max-width: 300px;
  margin: 10px 5px;
  border-radius: 5px;
  transition: 0.77s ease;
}
.container_right_top form input:focus {
  border: 1px solid #62b4ff;
  background-color: #fff;
}
.container_right_bottom_check {
  margin: 10px 0;
}
.container_right_bottom_check label {
  padding-left: 10px;
}

/* QUERIES */
@media screen and (max-width: 868px) {
  .profile-container {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 780px) {
  .container_right_top form {
    grid-template-columns: 1fr;
  }
}


/* ADMIN */
.admin_header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline; 
}
.admin_button{
  background-color: #62b4ff;
  box-shadow: 1px 2px 6px 4px #7e8a95;
  padding:  10px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  max-width: 180px;
  transition: 0.33s ease;
  font-size: 16px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.admin_button:hover{
color: #62b4ff;
background-color: #fff;
}