.why_trust_us_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.33s ease;
    padding: 50px 0;
}

.why_trust_us_main h1 {
    margin: 50px 0;
}
.why_trust_us_container{
    display: grid;
  transition: 0.33s ease;
 
 grid-template-columns: 1fr 1fr 1fr;  
  align-items: center; 
    
}

@media screen and (max-width: 1400px) {
    .why_trust_us_container{
        grid-template-columns: 1fr 1fr;
    }
    .why_trust_us_main h1 {
        margin-top: 100px;
        margin-bottom: 80px;
    }
    
}

@media screen and (max-width: 1200px) {
    .why_trust_us_container{
        grid-template-columns: 1fr;
    }
}
.why_trust_us_card{
    display: flex;
    flex-direction: column;
    width: 350px;
    transition: 0.33s ease;
    padding-left: 140px;
}

.why_trust_us_card h2{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 24px; 
    margin-bottom: 10px;
}

.why_trust_us_card p{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px; 
    margin-bottom: 10px;
}