.loginPage{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     background: linear-gradient(to left, rgb(159, 147, 147), #333);
}

.loginContainer{
   width: 500px; 
   background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
}

.loginContainerImg{
    height: 4.5rem; 
    object-fit: contain;
    margin: 10px 0;
    margin-top: 23px;
}

.loginContainerImg img{
    height: inherit;  
}
.loginContainer form {
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    padding-top: 40px;
    padding-bottom: 10px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.loginContainer form .form-group{
    width: 100%;
}

.userTypeControls{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.userTypeControls p {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    margin: 10px 0;
    transition: 0.6s ease;
    font-family:  'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 8px 15px;
    box-shadow: 1px 1px 1px 1px #333;
    cursor: pointer;
    opacity: 0.65;
}

.userTypeControls p.active {
   
    background-color: rgb(204, 204, 204);
    color: #000;
    opacity: 1;
}

.userTypeControls p:hover{
    background-color: #fff;
    color: #000;
}
.loginContainer form .form-group label{
    font-size: 22px;
    font-family:  'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 5px 0;
      color: #fff
  }
.loginContainer form .form-group input{
    padding: 10px;
  background: #fff;
    transition: 0.33s ease;
    color: rgb(207, 207, 207);
        font-family:  'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.loginContainer form .form-group input:focus{
    background: #333; 
    color: #fff;
}

.login_submit_button{
    
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 8px;
    font-size: 20px;
    font-family:  'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #fff;
    transition: 0.33s ease;
    cursor: pointer;
    padding: 10px 78px;
    margin-top: 22px;
    margin-bottom: 5px;
    text-align: center;
}
.login_submit_button:hover{
    background-color: #fff;
    color: #000;
} 
.sign_up_text{
    color: #fff;
    font-size: 15px;
    transition: 0.45s ease;
    margin-top: 35px;
}

.sign_up_text:hover{
    color: rgb(109, 109, 109);
}

@media screen and (max-width: 500px){
    .loginContainer{
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 0%;
    }
    .userTypeControls{
        flex-direction: column;
    }
}
 